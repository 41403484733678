<template>
    <cms-block v-bind="$props" @block-selected="selectBlock">
        <cms-component-image :component="block.image" v-bind="componentProps"/>
        <cms-component-text :component="block.label" v-bind="componentProps"/>
    </cms-block>
</template>

<script>
import CmsBlock from "@/components/editor/rendering/blocks/cms-block.vue";
import CmsComponentImage from "@/components/editor/rendering/components/cms-component-image.vue";
import CmsComponentText from "@/components/editor/rendering/components/cms-component-text.vue";

// cms-block-list-category #hot-reload-debug
export default {
    name: `cms-block-list-category`,
    components: {CmsComponentText, CmsComponentImage, CmsBlock},
    extends: CmsBlock
}
</script>

<style lang="scss" scoped>
</style>
